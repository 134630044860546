export default {
    navPc: ['关于我们', '线下场馆', '联系我们'],
    navH5: ['关于我们', '活动', '资讯'],
    navH52: ['免费试玩', '联系我们'],
    navH53: ['奖池', '场馆', '游戏', '什么是奖池'],
    footerH5: ['奖池', '场馆', '游戏', '什么是奖池', '关于我们', '活动', '资讯', '免费试玩'],
    desc1: '免费试玩',
    desc2: '视讯老虎机，赢在路上',
    desc3: '行业的创新者',
    desc4: '我们致力于将世界各地赌场内最受欢迎的老虎机游戏带到线上，打造全世界最多的机台和多款游戏的累积大奖池 操作体验还原线下，游戏体验超过线下',
    jackpotData: [
        { desc: '所有奖池总金额', number: '1000', unit1: '万+', unit2: '美元' },
        { desc: '连线奖池数量', number: '37', unit1: '', unit2: '' },
    ],
    venueData: [
        { desc: '正式运营机台，持续增加', number: '4000', unit1: '+', unit2: '' },
        { desc: '线下场馆规模', number: '20000', unit1: '+', unit2: '平方米' },
    ],
    connectData:[
        { desc: '线上赌场合作伙伴', number: '28', unit1: '', unit2: '家' },
        { desc: '语言支持', number: '9', unit1: '', unit2: '种' },
    ],
    mapData: [
        { desc: '线上赌场合作伙伴', number: '28', unit1: '', unit2: '家' },
        { desc: '所有奖池总金额', number: '1000', unit1: '万+', unit2: '美元' },
        { desc: '连线奖池数量', number: '37', unit1: '', unit2: '' },
        { desc: '游戏数量', number: '217', unit1: '', unit2: '' },
        { desc: '专业雇员', number: '1000', unit1: '+', unit2: '' },
        { desc: '正式运营机台，持续增加', number: '4000', unit1: '+', unit2: '' },
        { desc: '线下场馆规模', number: '20000', unit1: '+', unit2: '平方米' },
        { desc: '语言支持', number: '9', unit1: '', unit2: '种' },
    ],
    mapDesc: [
        'SHUFFLE MASTER代表产品“发牌机”该公司推出的老虎机如“黄金选择”等东方主题热门游戏备受玩家喜爱',
        'SCIENTIFIC GAME作为知名老虎机公司，推出累计宝盒升级玩法。超热门游戏如“JIN JI BAO XI”系列奖池。',
        'IGT公司历史悠久，作为全球顶尖老虎机公司，推出的埃及系列主题游戏备受玩家喜爱，埃及代表着神秘与冒险，让我们一起探索。',
        'Buffalo老虎机游戏以野生动物和自然景观为主题，游戏提供多种中奖组合和高额奖金，带您领略不同的风情',
        'DA FU DA GUI系列以中国幸运符号为主题，亚洲文化设计紧密相关，在玩家心中特别受欢迎，如"快乐永恒“游戏',
        '具有丰富的主题和丰厚奖金，特别是与中国文化相关的游戏。这些游戏备受喜爱，如“发发发 ”“财神到”等',
        'Coin Combo提供多种动物主题与亚洲文化相结合，提供三个积宝盒升级玩法，备受玩家喜爱',
        'Lightning Link提供多种主题和巨额奖金的老虎机游戏以及东方主题游戏备受玩家的喜爱',
        'ARUZE GAMING作为日本出色的游戏公司，推出弹珠机，老虎机等诸多热门游戏',
        'ARISTOCRAT作为澳大利亚最大的老虎机厂商，推出多款全球性游戏，爆款5 Dragons 系列'
    ],
    desc6: '我们的线下场馆',
    desc7: '线下6大场馆，支持承载上万台老虎机，24小时值班监控，保证玩家流畅的体验,欢迎您前往场馆参观',
    desc8: 'iSLOT提供数百种实体老虎机游戏，普通旋转的特殊玩法，免费游戏的倍数奖励，Jackpot的超级奖池金额应有尽有',
    desc9: 'iSLOT官方合作模式',
    desc10: 'iSLOT作为信誉品牌创新引领国际潮流，已先后建设针对不同客户的线上网站,建设全球基站保证玩家的流畅娱乐',
    desc11: '给予您不同的体验以及合作。',
    desc12: '合作伙伴',
    cooperateBtn: ['官方洗码代理', 'API合作'],
    desc13: '官方洗码代理网址：',
    desc14: '官方占成代理网址：',
    cooperateDesc: [
        '1.代理洗码享代理线总投注额（不包含自己账号）洗码',
        '2.每周一结算代理洗码佣金',
        '3.洗码佣金，派发至代理账号钱包',
        '4.周洗码有效投注，依场馆换算。iSLOT场馆有效投注换算为1:1,其他电子游戏场馆有效投注换算为1:0.35'
    ],
    cooperateDesc2: [
        '举例：代理向平台单次充值1,000,000U得代理占成60%，',
        '1.代理充值1,000,000，得2,500,000筹码',
        '2.代理将筹码销售给下级的利润，均代理所有',
    ],
    desc15: '梯度',
    desc16: '周有效投注（USDT）',
    desc17: '代理洗码 iSLOT',
    desc18: '代理等级',
    desc19: '等同占成',
    desc20: '单次充值USDT',
    desc21: '获得筹码',
    desc22: '合作优势',
    desc23: '代理责任',
    desc24: [
        '1. 极低的业务启动门槛：代理依折扣优惠买码，可获得最大利润空间',
        '2. 安全稳定的支付渠道：官方提供金流渠道，高效安全，费率低廉',
        '3. 精细化运营管理：代理可根据业务需求，灵活设置下级玩家洗码等优惠策略',
        '4. 安全收益低风险：官方1:1承担Grand Jackpot费用',
    ],
    desc25: [
        '1. 充值获得筹码和后台账号',
        '2. 代理负责下级的上下分和支付结算',
    ],
    desc26: 'API接入',
    desc27: [
        '1. 收取平台费 GGR 45%，可选择接入带Grand Jackpot机台',
        '2. 承上，接入后需实交2%投注额的彩金，Grand Jackpot由iSLOT承担',
        '3. 每次买入最低10万预付款，用以支付平台费及彩金上交！'
    ],
    desc28: 'iSLOT业务遍布全球，100%专注老虎机的创新并致力于卓越的客户服务，',
    desc29: '尖端的技术和对诚信的承诺，提供多种合作方式，我们随时为您提供帮助。',
    desc30: '姓名',
    desc31: '请填写您的姓名',
    desc32: '邮箱',
    desc33: '请填写您的邮箱',
    desc34: '所在地（选填）',
    desc35: '请选择您的所在地',
    desc36: '咨询类型',
    desc37: '请填写您的咨询类型',
    desc38: '咨询内容',
    desc39: '请填写您的咨询内容',
    desc40: 'API合作',
    desc41: '代理合作',
    desc42: '深度合作',
    desc43: '其他问题',
    desc44: '提交咨询',
    desc45: '官方邮箱：',
    desc46: '发送成功',
    desc47: '请填写完整信息',
    desc48: '请填写正确的邮箱',
    desc49: 'LIVE GRAND JACKPOT',
    next: '下一篇：',
    '游戏数量': '游戏数量',
    desc50:'访问受限',
    desc403:'因您所在地区法律法规限制导致无法访问我们网站，由此给您带来不便，我们深表歉意，如有任何疑问，请联系在线客服。',
    pioneerBead: '首创露珠图',
    moreRecommend: '更多推荐',
    otherGames: '其他游戏',
    gameIntroduce: '游戏介绍',
    highlights: '精彩回放',
    clickToMoreGames: '点击查看更多游戏',
    clickToMoreStrategy: '点击查看更多攻略',
    clickToMore: '点击查看更多',
    allGames: '全部游戏',
    playNow: '立即试玩',
    rateFaliue: '获取汇率失败，无法更新汇率信息',
}
