import React, { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import App from './pages/index';

const Home = lazy(() => import('@/pages/Home/index'))
const InformationList = lazy(() => import('@/pages/InformationPage/list'))
const InformationDetail = lazy(() => import('@/pages/InformationPage/detail'))
const AboutUs = lazy(() => import('@/pages/AboutUsPage'))
const Activity = lazy(() => import('@/pages/ActivityPage'))
const Jackpot = lazy(() => import('@/pages/JackpotPage'))
const Game = lazy(() => import('@/pages/GamePage'))
const NginxPage = lazy(() => import('@/pages/403'))
const BeadsPage = lazy(() => import('@/pages/BeadsPage/list'))
const GoodRecommendDetail = lazy(() => import('@/pages/BeadsPage/detail'))
const GameList = lazy(() => import('@/pages/GameList'))
const GameDetail = lazy(() => import('@/pages/GameList/detail'))
export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/information',
        element: <InformationList />,
      },
      {
        path: '/information/detail',
        element: <InformationDetail />,
      },
      {
        path: '/aboutUs',
        element: <AboutUs />,
      },
      {
        path: '/activity',
        element: <Activity />,
      },
      {
        path: '/jackpotList',
        element: <Jackpot />,
      },{
        path: '/game',
        element: <Game />,
      },
      {
        path: '/403',
        element: <NginxPage />,
      },
      {
        path: '/beads',
        element: <BeadsPage />,
      },
      {
        path: '/goodRecommendDetail',
        element: <GoodRecommendDetail />,
      },
      {
        path: '/gameList',
        element: <GameList />,
      },
      {
        path: '/gameList/detail',
        element: <GameDetail />,
      },
    ]
  },

]);
