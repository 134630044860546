// store.js
import { atom } from 'recoil';

export const dataState = atom({
  key: 'dataState',
  default: null,
});

export const gameDataState = atom({
  key: 'gameDataState',
  default: null,
});

export const screenState = atom(
  {
    key: 'isH5',
    default: null,
  },
)

export const langState = atom(
  {
    key: 'langState',
    default: null,
  },
)

export const gameUrlState = atom(
  {
    key: 'gameUrlState',
    default: null,
  },
)